.duration-picker-middle > * > .ant-input-number-group-addon,
.duration-picker-left > * > .ant-input-number-group-addon {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border-right-width: 0;
}


.duration-picker-middle > * > .ant-input-number,
.duration-picker-right > * > .ant-input-number {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}
